/** @jsx jsx */
import React, { useMemo, useState } from 'react'
import { useInterval, useTimeout } from 'react-use'
import { jsx } from 'theme-ui'
import { LoadingPage } from '../../components/LoadingPage'
import { publicDemoFiveUsersWithMultipleRaisesAndAnAllIn } from '../../serverMocks/data/publicDemoFiveUsersWithMultipleRaisesAndAnAllIn'
import {
  buildMockStateMachine,
  getGameDataProps,
  IMockDataStateMachineResponse,
} from '../../serverMocks/serverMockUtils'
import { GameDetailsActiveGame } from '../GameDetails/GameDetailsActiveGame'
import { IUseGameActionsFn } from '../GameDetails/useGameActions'
import { AppPageProps } from '../../types'

const useDemoProps = (stateMachine: IMockDataStateMachineResponse) => {
  stateMachine
  const [currentState, setCurrentState] = useState(stateMachine)
  const gameData = useMemo(() => getGameDataProps(currentState), [currentState])
  useInterval(
    () => {
      setCurrentState(currentState.getNextState())
    },
    currentState.currentGameState.gameHandsData[0].gameHand.finalResult ? null : 2000,
  )
  const getActions: IUseGameActionsFn = () => ({
    giveUserAdditionalChips: () => Promise.resolve(),
    setCurrentUserTableState: () => Promise.resolve(),
    voteToSetUserTableState: () => Promise.resolve(),
    check: () => Promise.resolve(),
    fold: () => Promise.resolve(),
    bet: () => Promise.resolve(),
  })
  const props = { gameData, getActions }
  return props
}

export const DemoGameDetails: React.FC<AppPageProps> = () => {
  const props = useDemoProps(buildMockStateMachine(publicDemoFiveUsersWithMultipleRaisesAndAnAllIn))
  const [isReady] = useTimeout(1400)
  if (!isReady()) {
    return <LoadingPage title="Demo Poker Game" noIndex />
  }

  return <GameDetailsActiveGame gameMockType="disable-actions" {...props} />
}
