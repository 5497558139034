import _ from 'lodash'
import { IExportedMock, IExportedMockGameState } from './serverMockTypes'
import { logger } from '../utils/logger'
import { applyChange } from 'deep-diff'
import { IGameData } from '../page/GameDetails/useGameData'

export interface IMockDataStateMachineResponse {
  currentUserId: IExportedMock['currentUserId']
  gameStates: IExportedMockGameState[]
  currentGameState: IExportedMockGameState
  getNextState: () => IMockDataStateMachineResponse
}

export const getGameDataProps = (stateMachine: IMockDataStateMachineResponse): IGameData => {
  const { currentGameState, currentUserId } = stateMachine
  currentGameState.gameHandsData.reverse()
  const currentHandData = currentGameState.gameHandsData[0]
  const { users, game } = currentGameState
  const currentUserHand = currentHandData.userHands.find((hand) => hand.userId === currentUserId)!
  return {
    authUser: {} as any,
    game,
    users,
    currentUserHand,
    currentUserId,
    currentHand: currentHandData.gameHand,
    gameHands: currentGameState.gameHandsData.map(({ gameHand }) => gameHand),
  }
}

export const buildMockStateMachine = (
  exportedMock: IExportedMock,
): IMockDataStateMachineResponse => {
  const { currentUserId } = exportedMock
  const gameStates = [_.cloneDeep(exportedMock.initialGameState)]
  exportedMock.diffGroups.forEach((diffs) => {
    const mostRecentState = _.cloneDeep(gameStates[gameStates.length - 1])
    diffs.forEach((diff) => {
      applyChange(mostRecentState, true, diff)
    })
    gameStates.push(mostRecentState)
  })
  let currentGameStateIndex = 0
  const sharedProps = {
    currentUserId,
    gameStates,
  }
  const getNextState = (): IMockDataStateMachineResponse => {
    logger.debug('computing next gameHandState', 'serverMockUtils', {
      currentGameStateIndex,
    })
    const newGameState = gameStates[currentGameStateIndex + 1]
    if (newGameState) {
      currentGameStateIndex += 1
      return {
        ...sharedProps,
        getNextState,
        currentGameState: newGameState,
      }
    }
    logger.debug('No new state to transition to', 'serverMockUtils')
    return {
      ...sharedProps,
      getNextState,
      currentGameState: gameStates[currentGameStateIndex],
    }
  }
  return {
    ...sharedProps,
    getNextState,
    currentGameState: gameStates[currentGameStateIndex],
  }
}
