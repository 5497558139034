import { IExportedMock } from '../serverMockTypes'

export const publicDemoFiveUsersWithMultipleRaisesAndAnAllIn = {
  initialGameState: {
    game: {
      id: '6fbe024f-2316-4265-a6e8-d65a837e308a',
      userIds: [
        '7fd0c775dad1468e3cf42f597018bb32',
        '17ad55a9b8384777496330d23e59d520',
        'c22e1816539af64a7eadc2b81c3a1905',
        'cff97b661e355d03528a657daa6342d3',
        '447bb222246050fedc77510c9788d92f',
      ],
      datetimeCreated: '2020-02-10T10:00:00.000Z',
      userRoles: {
        '7fd0c775dad1468e3cf42f597018bb32': 'ADMIN',
      },
      totalChipsGiven: {
        '7fd0c775dad1468e3cf42f597018bb32': 500,
        '17ad55a9b8384777496330d23e59d520': 1000,
        c22e1816539af64a7eadc2b81c3a1905: 1000,
        cff97b661e355d03528a657daa6342d3: 1000,
        '447bb222246050fedc77510c9788d92f': 1000,
      },
      pendingUserEmailAccessRequests: [],
      initialRules: {
        initialChipsAmount: 1000,
        startingBlindAmount: 10,
        gameType: 'TEXAS_HOLDEM_NO_LIMIT',
        numberOfHandsBetweenBlindIncreases: 10,
      },
    },
    users: [
      {
        id: '7fd0c775dad1468e3cf42f597018bb32',
        email: 'BeefyT',
        username: '',
        datetimeCreated: '2020-02-10T10:00:00.000Z',
      },
      {
        id: '17ad55a9b8384777496330d23e59d520',
        email: 'Rick',
        username: '',
        datetimeCreated: '2020-02-10T10:00:00.000Z',
      },
      {
        id: 'c22e1816539af64a7eadc2b81c3a1905',
        email: 'Zook',
        username: '',
        datetimeCreated: '2020-02-10T10:00:00.000Z',
      },
      {
        id: 'cff97b661e355d03528a657daa6342d3',
        email: 'Morty',
        username: '',
        datetimeCreated: '2020-02-10T10:00:00.000Z',
      },
      {
        id: '447bb222246050fedc77510c9788d92f',
        email: 'Pawlicy',
        username: '',
        datetimeCreated: '2020-02-10T10:00:00.000Z',
      },
    ],
    gameHandsData: [
      {
        gameHand: {
          id: 'b447c311-77e1-44e4-9280-1ae3e4479099',
          gameId: '6fbe024f-2316-4265-a6e8-d65a837e308a',
          userTablePosition: [
            '7fd0c775dad1468e3cf42f597018bb32',
            '17ad55a9b8384777496330d23e59d520',
            'c22e1816539af64a7eadc2b81c3a1905',
            'cff97b661e355d03528a657daa6342d3',
            '447bb222246050fedc77510c9788d92f',
          ],
          visibleCards: [],
          userTableStates: {
            '7fd0c775dad1468e3cf42f597018bb32': 'PLAYING',
            '17ad55a9b8384777496330d23e59d520': 'PLAYING',
            c22e1816539af64a7eadc2b81c3a1905: 'PLAYING',
            cff97b661e355d03528a657daa6342d3: 'PLAYING',
            '447bb222246050fedc77510c9788d92f': 'PLAYING',
          },
          encryptedCards: [
            'MOCK_ENCRYPT:2c',
            'MOCK_ENCRYPT:3d',
            'MOCK_ENCRYPT:4h',
            'MOCK_ENCRYPT:Jc',
            'MOCK_ENCRYPT:Ad',
          ],
          bettingRounds: [
            {
              actions: [],
            },
          ],
          leftoverWinningsAmount: 0,
          chipsAtStartOfHand: {
            '7fd0c775dad1468e3cf42f597018bb32': 500,
            '17ad55a9b8384777496330d23e59d520': 1000,
            c22e1816539af64a7eadc2b81c3a1905: 1000,
            cff97b661e355d03528a657daa6342d3: 1000,
            '447bb222246050fedc77510c9788d92f': 1000,
          },
          rules: {
            gameType: 'TEXAS_HOLDEM_NO_LIMIT',
            numberOfHandsUntilBlindIncrease: 10,
            numberOfHandsBetweenBlindIncreases: 10,
            blindAmount: 10,
          },
          dealerUserId: '7fd0c775dad1468e3cf42f597018bb32',
          nextUserIdToAct: 'cff97b661e355d03528a657daa6342d3',
          datetimeCreated: '2020-02-10T10:00:00.000Z',
        },
        userHands: [
          {
            userId: '7fd0c775dad1468e3cf42f597018bb32',
            gameHandId: 'b447c311-77e1-44e4-9280-1ae3e4479099',
            cards: ['6c', '6h'],
          },
          {
            userId: '17ad55a9b8384777496330d23e59d520',
            gameHandId: 'b447c311-77e1-44e4-9280-1ae3e4479099',
            cards: ['2d', '2c'],
          },
          {
            userId: 'c22e1816539af64a7eadc2b81c3a1905',
            gameHandId: 'b447c311-77e1-44e4-9280-1ae3e4479099',
            cards: ['7c', '7h'],
          },
          {
            userId: 'cff97b661e355d03528a657daa6342d3',
            gameHandId: 'b447c311-77e1-44e4-9280-1ae3e4479099',
            cards: ['Qs', 'Kd'],
          },
          {
            userId: '447bb222246050fedc77510c9788d92f',
            gameHandId: 'b447c311-77e1-44e4-9280-1ae3e4479099',
            cards: ['8h', '7d'],
          },
        ],
      },
    ],
  },
  description: 'public demo five users with multiple raises and an all in',
  currentUserId: '7fd0c775dad1468e3cf42f597018bb32',
  diffGroups: [
    [
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds', 0, 'actions'],
        index: 0,
        item: {
          kind: 'N',
          rhs: {
            type: 'BET',
            chipsAmount: 10,
            datetimeCreated: '2020-02-10T10:02:00.000Z',
            betType: 'call',
            userId: 'cff97b661e355d03528a657daa6342d3',
          },
        },
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'nextUserIdToAct'],
        lhs: 'cff97b661e355d03528a657daa6342d3',
        rhs: '447bb222246050fedc77510c9788d92f',
      },
    ],
    [
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds', 0, 'actions'],
        index: 1,
        item: {
          kind: 'N',
          rhs: {
            type: 'FOLD',
            datetimeCreated: '2020-02-10T10:04:00.000Z',
            userId: '447bb222246050fedc77510c9788d92f',
          },
        },
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'nextUserIdToAct'],
        lhs: '447bb222246050fedc77510c9788d92f',
        rhs: '7fd0c775dad1468e3cf42f597018bb32',
      },
    ],
    [
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds', 0, 'actions'],
        index: 2,
        item: {
          kind: 'N',
          rhs: {
            type: 'BET',
            chipsAmount: 10,
            datetimeCreated: '2020-02-10T10:06:00.000Z',
            betType: 'call',
            userId: '7fd0c775dad1468e3cf42f597018bb32',
          },
        },
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'nextUserIdToAct'],
        lhs: '7fd0c775dad1468e3cf42f597018bb32',
        rhs: '17ad55a9b8384777496330d23e59d520',
      },
    ],
    [
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds', 0, 'actions'],
        index: 3,
        item: {
          kind: 'N',
          rhs: {
            type: 'BET',
            chipsAmount: 5,
            datetimeCreated: '2020-02-10T10:08:00.000Z',
            betType: 'call',
            userId: '17ad55a9b8384777496330d23e59d520',
          },
        },
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'nextUserIdToAct'],
        lhs: '17ad55a9b8384777496330d23e59d520',
        rhs: 'c22e1816539af64a7eadc2b81c3a1905',
      },
    ],
    [
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'visibleCards'],
        index: 2,
        item: {
          kind: 'N',
          rhs: '4h',
        },
      },
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'visibleCards'],
        index: 1,
        item: {
          kind: 'N',
          rhs: '3d',
        },
      },
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'visibleCards'],
        index: 0,
        item: {
          kind: 'N',
          rhs: '2c',
        },
      },
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'encryptedCards'],
        index: 4,
        item: {
          kind: 'D',
          lhs: 'MOCK_ENCRYPT:Ad',
        },
      },
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'encryptedCards'],
        index: 3,
        item: {
          kind: 'D',
          lhs: 'MOCK_ENCRYPT:Jc',
        },
      },
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'encryptedCards'],
        index: 2,
        item: {
          kind: 'D',
          lhs: 'MOCK_ENCRYPT:4h',
        },
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'encryptedCards', 1],
        lhs: 'MOCK_ENCRYPT:3d',
        rhs: 'MOCK_ENCRYPT:Ad',
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'encryptedCards', 0],
        lhs: 'MOCK_ENCRYPT:2c',
        rhs: 'MOCK_ENCRYPT:Jc',
      },
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds'],
        index: 1,
        item: {
          kind: 'N',
          rhs: {
            actions: [],
          },
        },
      },
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds', 0, 'actions'],
        index: 4,
        item: {
          kind: 'N',
          rhs: {
            type: 'CHECK',
            datetimeCreated: '2020-02-10T10:10:00.000Z',
            userId: 'c22e1816539af64a7eadc2b81c3a1905',
          },
        },
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'nextUserIdToAct'],
        lhs: 'c22e1816539af64a7eadc2b81c3a1905',
        rhs: '17ad55a9b8384777496330d23e59d520',
      },
    ],
    [
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds', 1, 'actions'],
        index: 0,
        item: {
          kind: 'N',
          rhs: {
            type: 'BET',
            chipsAmount: 88,
            datetimeCreated: '2020-02-10T10:12:00.000Z',
            betType: 'bet',
            userId: '17ad55a9b8384777496330d23e59d520',
          },
        },
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'nextUserIdToAct'],
        lhs: '17ad55a9b8384777496330d23e59d520',
        rhs: 'c22e1816539af64a7eadc2b81c3a1905',
      },
    ],
    [
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds', 1, 'actions'],
        index: 1,
        item: {
          kind: 'N',
          rhs: {
            type: 'BET',
            chipsAmount: 176,
            datetimeCreated: '2020-02-10T10:14:00.000Z',
            betType: 'raise',
            userId: 'c22e1816539af64a7eadc2b81c3a1905',
          },
        },
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'nextUserIdToAct'],
        lhs: 'c22e1816539af64a7eadc2b81c3a1905',
        rhs: 'cff97b661e355d03528a657daa6342d3',
      },
    ],
    [
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds', 1, 'actions'],
        index: 2,
        item: {
          kind: 'N',
          rhs: {
            type: 'BET',
            chipsAmount: 264,
            datetimeCreated: '2020-02-10T10:16:00.000Z',
            betType: 'raise',
            userId: 'cff97b661e355d03528a657daa6342d3',
          },
        },
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'nextUserIdToAct'],
        lhs: 'cff97b661e355d03528a657daa6342d3',
        rhs: '7fd0c775dad1468e3cf42f597018bb32',
      },
    ],
    [
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds', 1, 'actions'],
        index: 3,
        item: {
          kind: 'N',
          rhs: {
            type: 'FOLD',
            datetimeCreated: '2020-02-10T10:18:00.000Z',
            userId: '7fd0c775dad1468e3cf42f597018bb32',
          },
        },
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'nextUserIdToAct'],
        lhs: '7fd0c775dad1468e3cf42f597018bb32',
        rhs: '17ad55a9b8384777496330d23e59d520',
      },
    ],
    [
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds', 1, 'actions'],
        index: 4,
        item: {
          kind: 'N',
          rhs: {
            type: 'BET',
            chipsAmount: 176,
            datetimeCreated: '2020-02-10T10:20:00.000Z',
            betType: 'call',
            userId: '17ad55a9b8384777496330d23e59d520',
          },
        },
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'nextUserIdToAct'],
        lhs: '17ad55a9b8384777496330d23e59d520',
        rhs: 'c22e1816539af64a7eadc2b81c3a1905',
      },
    ],
    [
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'visibleCards'],
        index: 3,
        item: {
          kind: 'N',
          rhs: 'Jc',
        },
      },
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'encryptedCards'],
        index: 1,
        item: {
          kind: 'D',
          lhs: 'MOCK_ENCRYPT:Ad',
        },
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'encryptedCards', 0],
        lhs: 'MOCK_ENCRYPT:Jc',
        rhs: 'MOCK_ENCRYPT:Ad',
      },
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds'],
        index: 2,
        item: {
          kind: 'N',
          rhs: {
            actions: [],
          },
        },
      },
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds', 1, 'actions'],
        index: 5,
        item: {
          kind: 'N',
          rhs: {
            type: 'BET',
            chipsAmount: 88,
            datetimeCreated: '2020-02-10T10:22:00.000Z',
            betType: 'call',
            userId: 'c22e1816539af64a7eadc2b81c3a1905',
          },
        },
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'nextUserIdToAct'],
        lhs: 'c22e1816539af64a7eadc2b81c3a1905',
        rhs: '17ad55a9b8384777496330d23e59d520',
      },
    ],
    [
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds', 2, 'actions'],
        index: 0,
        item: {
          kind: 'N',
          rhs: {
            type: 'BET',
            chipsAmount: 726,
            datetimeCreated: '2020-02-10T10:24:00.000Z',
            betType: 'bet',
            userId: '17ad55a9b8384777496330d23e59d520',
          },
        },
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'nextUserIdToAct'],
        lhs: '17ad55a9b8384777496330d23e59d520',
        rhs: 'c22e1816539af64a7eadc2b81c3a1905',
      },
    ],
    [
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds', 2, 'actions'],
        index: 1,
        item: {
          kind: 'N',
          rhs: {
            type: 'BET',
            chipsAmount: 726,
            datetimeCreated: '2020-02-10T10:26:00.000Z',
            betType: 'call',
            userId: 'c22e1816539af64a7eadc2b81c3a1905',
          },
        },
      },
      {
        kind: 'E',
        path: ['gameHandsData', 0, 'gameHand', 'nextUserIdToAct'],
        lhs: 'c22e1816539af64a7eadc2b81c3a1905',
        rhs: 'cff97b661e355d03528a657daa6342d3',
      },
    ],
    [
      {
        kind: 'A',
        path: ['gameHandsData'],
        index: 1,
        item: {
          kind: 'N',
          rhs: {
            gameHand: {
              id: 'c55c45ec-6ef0-4a79-91cf-1759f663cec9',
              gameId: '6fbe024f-2316-4265-a6e8-d65a837e308a',
              userTablePosition: [
                '7fd0c775dad1468e3cf42f597018bb32',
                '17ad55a9b8384777496330d23e59d520',
                'c22e1816539af64a7eadc2b81c3a1905',
                'cff97b661e355d03528a657daa6342d3',
                '447bb222246050fedc77510c9788d92f',
              ],
              visibleCards: [],
              userTableStates: {
                '7fd0c775dad1468e3cf42f597018bb32': 'PLAYING',
                '17ad55a9b8384777496330d23e59d520': 'PLAYING',
                c22e1816539af64a7eadc2b81c3a1905: 'OUT',
                cff97b661e355d03528a657daa6342d3: 'PLAYING',
                '447bb222246050fedc77510c9788d92f': 'PLAYING',
              },
              encryptedCards: [
                'MOCK_ENCRYPT:2c',
                'MOCK_ENCRYPT:3d',
                'MOCK_ENCRYPT:4h',
                'MOCK_ENCRYPT:Jc',
                'MOCK_ENCRYPT:Ad',
              ],
              bettingRounds: [
                {
                  actions: [],
                },
              ],
              leftoverWinningsAmount: 0,
              chipsAtStartOfHand: {
                '7fd0c775dad1468e3cf42f597018bb32': 490,
                '17ad55a9b8384777496330d23e59d520': 2284,
                c22e1816539af64a7eadc2b81c3a1905: 0,
                cff97b661e355d03528a657daa6342d3: 726,
                '447bb222246050fedc77510c9788d92f': 1000,
              },
              rules: {
                gameType: 'TEXAS_HOLDEM_NO_LIMIT',
                numberOfHandsUntilBlindIncrease: 9,
                numberOfHandsBetweenBlindIncreases: 10,
                blindAmount: 10,
              },
              dealerUserId: '17ad55a9b8384777496330d23e59d520',
              nextUserIdToAct: '7fd0c775dad1468e3cf42f597018bb32',
              datetimeCreated: '2020-02-10T10:28:00.000Z',
            },
            userHands: [
              {
                userId: '7fd0c775dad1468e3cf42f597018bb32',
                gameHandId: 'c55c45ec-6ef0-4a79-91cf-1759f663cec9',
                cards: ['6c', '6h'],
              },
              {
                userId: '17ad55a9b8384777496330d23e59d520',
                gameHandId: 'c55c45ec-6ef0-4a79-91cf-1759f663cec9',
                cards: ['2d', '2c'],
              },
              {
                userId: 'cff97b661e355d03528a657daa6342d3',
                gameHandId: 'c55c45ec-6ef0-4a79-91cf-1759f663cec9',
                cards: ['7c', '7h'],
              },
              {
                userId: '447bb222246050fedc77510c9788d92f',
                gameHandId: 'c55c45ec-6ef0-4a79-91cf-1759f663cec9',
                cards: ['8d', 'Qd'],
              },
            ],
          },
        },
      },
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'visibleCards'],
        index: 4,
        item: {
          kind: 'N',
          rhs: 'Ad',
        },
      },
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'encryptedCards'],
        index: 0,
        item: {
          kind: 'D',
          lhs: 'MOCK_ENCRYPT:Ad',
        },
      },
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds'],
        index: 3,
        item: {
          kind: 'N',
          rhs: {
            actions: [],
          },
        },
      },
      {
        kind: 'A',
        path: ['gameHandsData', 0, 'gameHand', 'bettingRounds', 2, 'actions'],
        index: 2,
        item: {
          kind: 'N',
          rhs: {
            type: 'FOLD',
            datetimeCreated: '2020-02-10T10:28:00.000Z',
            userId: 'cff97b661e355d03528a657daa6342d3',
          },
        },
      },
      {
        kind: 'N',
        path: ['gameHandsData', 0, 'gameHand', 'finalResult'],
        rhs: {
          datetimeCompleted: '2020-02-10T10:28:00.000Z',
          automaticallyVisibleUserHands: [
            {
              userId: '17ad55a9b8384777496330d23e59d520',
              cards: ['2d', '2c'],
              amountWon: 1284,
              state: 'ALL_IN',
            },
          ],
        },
      },
    ],
  ],
} as IExportedMock
